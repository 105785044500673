import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/seo';

function ContactPage () {
  return (
    <Layout>
      <Seo title="Kontakt" />
    </Layout>
  );
}

export default ContactPage;
